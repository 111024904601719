import React from 'react'
import * as S from './styled'

interface IProps {
  size?: number
}

const LogoIcon = ({ size = 24 }: IProps) => (
  <S.LogoIconWrapper
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 254 254"
  >
    <path d="M208.83 125.42c2.53 1.03 4.88 1.82 7.09 2.9 13.27 6.44 21.17 17.23 24.61 31.3 3.72 15.23 2.49 30.35-2.93 44.96-6.47 17.44-19.67 27.82-36.97 33.48-6.27 2.05-12.75 3.15-19.29 3.93-1.81.22-2.26-.37-2.25-2.13.07-11.5.05-23 .01-34.5-.01-1.34.28-2.11 1.7-2.55 14.43-4.49 19.96-16.77 19-30.85-.2-2.98-.55-5.95-1.32-8.87-2.75-10.4-9.43-16.57-19.93-18.63a46.08 46.08 0 0 0-8.94-.87c-25.91.01-51.83-.03-77.74.03-2.21.01-2.97-.45-2.93-2.85.16-9.25.14-18.5.01-27.74-.03-2.21.56-2.78 2.76-2.77 22.91.08 45.83.09 68.74.02 6.08-.02 12.14-.49 17.97-2.54 9.61-3.38 14.96-10.25 16.36-20.17.72-5.13.56-10.3-.51-15.42-2.27-10.82-9.42-16.4-19.81-18.53-4.51-.92-9.07-1.21-13.66-1.21-23 .01-45.99-.04-68.99.04-2.33.01-2.89-.64-2.86-2.91.12-10.91.1-21.83.01-32.74-.02-1.92.57-2.35 2.4-2.34 24.83.06 49.66-.09 74.49.11 13.84.11 27.38 2.26 40.08 8.16 18.7 8.69 28.69 23.64 31.18 43.85 1.35 10.96.9 21.78-3.22 32.21-4.64 11.73-12.47 20.54-23.94 25.99-.3.14-.56.32-1.12.64z" />
    <path d="M20.89 128.59c0-37.08.02-74.16-.05-111.24 0-2.23.49-2.93 2.84-2.91 12.33.13 24.66.13 37 0 2.4-.03 2.8.75 2.8 2.93-.06 61.49-.03 122.99-.1 184.48 0 2.55.56 3.36 3.22 3.27 6.66-.21 13.35.23 19.99-.13 14.4-.79 28.8-.29 43.2-.32 7.31-.01 14.62.54 21.93.36 1.42-.03 1.85.41 1.85 1.83-.05 11.5-.05 23 0 34.5.01 1.36-.47 1.69-1.73 1.66-3.58-.07-7.17-.02-10.75-.02-39.08 0-78.16-.02-117.24.06-2.39 0-3.02-.54-3.01-2.98.08-37.17.05-74.33.05-111.49z" />
  </S.LogoIconWrapper>
)

export default LogoIcon
